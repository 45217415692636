import {
  AIR_PREFERENCES,
  CAR_PREFERENCES,
  ROOM_TYPES,
  TRAIN_PREFERENCES,
} from 'constants/preferences';
import {
  applySpec,
  compose,
  converge,
  drop,
  evolve,
  find,
  groupBy,
  head,
  identity,
  indexOf,
  map,
  path,
  pathOr,
  propEq,
  sort,
} from 'ramda';
import { IEmail, IPhone, IProfile } from 'types/profile';
import { ISearchEntity } from 'types/search';
import { pickFullString } from './common';
import {
  AIR_CODES,
  CAR_CODES,
  HOTEL_CODES,
  RAIL_CODES,
  getCodes,
} from './membership';
import { formatCompanyResponse } from 'components/pages/Company/Company';

export const PHONE_ORDER_ARR = [
  { code: 'C', label: 'Mobile' },
  { code: 'H', label: 'Home' },
  { code: 'S', label: 'Assistant' },
  { code: 'B', label: 'Business' },
  { code: 'A', label: 'Agency' },
  { code: 'F', label: 'Fax' },
  { code: 'P', label: 'Pager' },
  { code: 'R', label: 'TRMC' },
];

export const EMAIL_ORDER_ARR = [
  { code: 'BCC', label: 'Blind Copy' },
  { code: 'CC', label: 'Additional' },
  { code: 'EBILL', label: 'E-mail Invoice' },
  { code: 'EBILLEFARE', label: 'EBill & EFare' },
  { code: 'EFARE', label: 'EFare Only' },
  { code: 'O', label: 'Optional' },
  { code: 'P', label: 'E-mail Itinerary' },
];

export const formatName: (profile: ISearchEntity | undefined) => string =
  converge(
    (...args) => {
      const [prefix, fn, mn, ln, suffix] = args;
      const fullName = `${prefix} ${fn} ${mn} ${ln} ${suffix}`;
      return fullName.trim().length ? fullName : 'N/A';
    },
    [
      pathOr('', ['Prefix']),
      pathOr('', ['FirstName']),
      pathOr('', ['MiddleName']),
      pathOr('', ['LastName']),
      pathOr('', ['Suffix']),
    ]
  );

export const formatEmail = (profile: ISearchEntity | undefined) =>
  profile?.PrimaryEmail || 'N/A';

const sortedPhones = compose(
  sort(
    (a: IPhone, b: IPhone) =>
      indexOf(
        a.PhoneType,
        map(({ code }) => code, PHONE_ORDER_ARR)
      ) -
      indexOf(
        b.PhoneType,
        map(({ code }) => code, PHONE_ORDER_ARR)
      )
  ),
  pathOr([], ['Phones'])
);
const sortedEmails = sort(
  (a: IEmail, b: IEmail) =>
    indexOf(
      a.AddressType,
      map(({ code }) => code, EMAIL_ORDER_ARR)
    ) -
    indexOf(
      b.AddressType,
      map(({ code }) => code, EMAIL_ORDER_ARR)
    )
);
export const formatPCC = (PseudoCity: string, StarBar: string) => {
  return `${PseudoCity}-${StarBar}`;
};
export const formatCompany = (profile: ISearchEntity | undefined) => {
  if (profile?.Company) {
    const { Name, PseudoCity, StarBar } = profile?.Company;
    if (Name || PseudoCity || StarBar) {
      if (PseudoCity && StarBar) {
        return `${Name || 'N/A'} (${formatPCC(PseudoCity, StarBar)})`;
      }
      if (Name) {
        return Name;
      }
    }
  }
  return 'N/A';
};
const profileSpec = {
  GdsLocator: pickFullString('N/A', ['GDSLocator']),
  Manager: pathOr([], ['Manager']),
  VIP: pathOr('', ['VIP']),
  additionalEmails: compose(
    map((email: IEmail) => {
      const label =
        EMAIL_ORDER_ARR.find(({ code }) => code === email.AddressType)?.label ??
        'Unknown Email Type';
      return {
        label,
        value: email.Email,
      };
    }),
    sortedEmails,
    converge(
      (...args: any) => {
        const [emails, primaryEmail] = args;
        return emails.filter(
          ({ AddressType, Email }: IEmail) =>
            !(
              Email.toLowerCase() === primaryEmail.toLowerCase() &&
              AddressType === 'P'
            )
        );
      },
      [pathOr([], ['Emails']), pathOr(null, ['PrimaryEmail'])]
    )
  ),
  additionalPhones: compose(
    map((phone: IPhone) => {
      const { CountryCode, PhoneNumber, PhoneType } = phone;
      const label =
        PHONE_ORDER_ARR.find(({ code }) => code === PhoneType)?.label ??
        'Unknown Phone Type';
      return {
        label,
        value: `${CountryCode ? `+${CountryCode} ` : ''}${PhoneNumber}`,
      };
    }),
    drop(1),
    sortedPhones
  ),
  airPreferences: compose(
    converge(
      (...preferences: any) => {
        const [aisle, left, forward, bulkhead, meal, dep] = preferences;
        return {
          'Aisle/Window': AIR_PREFERENCES.Aisle[aisle],
          'Bulkhead/Exit': AIR_PREFERENCES.Bulkhead[bulkhead],
          'Depart Airport': dep,
          'Forward/Tail': AIR_PREFERENCES.Forward[forward],
          'Left/Right': AIR_PREFERENCES.Left[left],
          Meal: AIR_PREFERENCES.Meal[meal],
        };
      },
      [
        pathOr(false, ['AisleWindowPreference', 'Code']),
        pathOr(false, ['LeftRightPreference', 'Code']),
        pathOr(false, ['ForwardTailPreference', 'Code']),
        pathOr(false, ['BulkheadExitPreference', 'Code']),
        pathOr(false, ['MealPreference', 'Code']),
        pathOr(false, ['DepartureAirport', 'IATACode']),
      ]
    ),
    pathOr({}, ['AirPreferences'])
  ),
  carPreferences: compose(
    converge(
      (...preferences: any) => {
        const [smoking, carSize, eq1, eq2, eq3, eq4] = preferences;
        return {
          'Car Size': CAR_PREFERENCES.Size[carSize],
          'Equip. Option 1': CAR_PREFERENCES.Equipment[eq1],
          'Equip. Option 2': CAR_PREFERENCES.Equipment[eq2],
          'Equip. Option 3': CAR_PREFERENCES.Equipment[eq3],
          'Equip. Option 4': CAR_PREFERENCES.Equipment[eq4],
          'Non-Smoking': smoking,
        };
      },
      [
        pathOr(false, ['SmokingPreference', 'NonSmoking']),
        pathOr(false, ['CarSizePreference', 'AcrissCode']),
        pathOr(false, ['EquipmentPreference1', 'EquipmentCode']),
        pathOr(false, ['EquipmentPreference2', 'EquipmentCode']),
        pathOr(false, ['EquipmentPreference3', 'EquipmentCode']),
        pathOr(false, ['EquipmentPreference4', 'EquipmentCode']),
      ]
    ),
    pathOr({}, ['CarPreferences'])
  ),
  company: formatCompany,
  companyGuid: (v: ISearchEntity) => v?.Company?.CompanyGuid || 'N/A',
  costCenter: pickFullString('N/A', ['CostCenter']),
  email: formatEmail,
  employeeId: pickFullString('N/A', ['EmployeeID']),
  firstName: pathOr('', ['FirstName']),
  fullName: formatName,
  guid: pathOr('N/A', ['TravelerGuid']),
  hotelPreferences: compose(
    converge(
      (...preferences: any) => {
        const [roomCode, smoking, handicap, aaa, aarp, gov, military] =
          preferences;
        return {
          'AAA Rate': aaa,
          'AARP Rate': aarp,
          'Gov. Rate': gov,
          'Handicapped required': handicap,
          'Military Rate': military,
          'Non-Smoking': smoking,
          'Room Type': ROOM_TYPES[roomCode],
        };
      },
      [
        pathOr(false, ['RoomTypePreference', 'RoomTypeCode']),
        pathOr(false, ['SmokingPreference', 'NonSmoking']),
        pathOr(false, ['HandicappedPreference', 'RequireHandicapped']),
        pathOr(false, ['DiscountRateEligibility', 'AAARate', 'IsEligible']),
        pathOr(false, ['DiscountRateEligibility', 'AARPRate', 'IsEligible']),
        pathOr(false, [
          'DiscountRateEligibility',
          'GovernmentRate',
          'IsEligible',
        ]),
        pathOr(false, [
          'DiscountRateEligibility',
          'MilitaryRate',
          'IsEligible',
        ]),
      ]
    ),
    pathOr({}, ['HotelPreferences'])
  ),
  isArranger: path(['IsArranger']),
  lastName: pathOr('', ['LastName']),
  membershipNumbers: compose(
    evolve<any>({
      A: getCodes(AIR_CODES),
      C: getCodes(CAR_CODES),
      H: getCodes(HOTEL_CODES),
      R: getCodes(RAIL_CODES),
    }),
    groupBy(pathOr('', ['Vendor', 'VendorType', 'Code'])),
    pathOr([], ['MembershipNumbers'])
  ),
  nickName: pickFullString('N/A', ['NickName']),
  pcc: pickFullString('N/A', ['Company', 'PseudoCity']),
  phone: compose(
    converge(
      (...args: any) => {
        const [code, number] = args;
        if (!(code || number)) return 'N/A';
        return `${code ? `+${code} ` : ''}${number || ''}`;
      },
      [
        pathOr('', ['CountryCode']),
        compose((v: string) => {
          if (!v) {
            return '';
          }
          if (v.length !== 10) {
            return v;
          }
          const [, ...format] = v.match(/(.{3})(.{3})(.{4})/) as any;
          return format.join(' ');
        }, pathOr('', ['PhoneNumber'])),
      ]
    ),
    head,
    sortedPhones
  ),
  phoneTypeName: compose(
    pathOr('', ['label']),
    (type) => find(propEq('code', type))(PHONE_ORDER_ARR),
    pathOr('', ['PhoneType']),
    head,
    sortedPhones
  ),
  phones: pathOr([], ['Phones']),
  position: pickFullString('N/A', ['JobTitle']),
  railPreferences: compose(
    converge(
      (...preferences: any) => {
        const [
          smoking,
          railClass,
          carType,
          zoneCode,
          seatNumber,
          seatType,
          seatOption,
          seatPosition,
          wagonCode,
          berthLevel,
          berthCompartment,
        ] = preferences;
        return {
          'Berth Compartment':
            TRAIN_PREFERENCES.BerthCompartment[berthCompartment],
          'Berth Level': TRAIN_PREFERENCES.BerthLevel[berthLevel],
          'Car Type': TRAIN_PREFERENCES.CarType[carType],
          Class: TRAIN_PREFERENCES.Class[railClass],
          'Non-Smoking': smoking,
          'Seat Number': seatNumber,
          'Seat Option': TRAIN_PREFERENCES.SeatOption[seatOption],
          'Seat Position': TRAIN_PREFERENCES.SeatPosition[seatPosition],
          'Seat Type': TRAIN_PREFERENCES.SeatType[seatType],
          'Wagon Code': TRAIN_PREFERENCES.WagonCode[wagonCode],
          'Zone Code': TRAIN_PREFERENCES.ZoneCode[zoneCode]
        };
      },
      [
        pathOr(false, ['SmokingPreference', 'NonSmoking']),
        pathOr(false, ['ClassPreference', 'ClassCode']),
        pathOr(false, ['CarTypePreference', 'CarType']),
        pathOr(false, ['ZoneCodePreference', 'ZoneCode']),
        pathOr(false, ['SeatNumber']),
        pathOr(false, ['SeatTypePreference', 'SeatType']),
        pathOr(false, ['SeatOptionPreference', 'SeatOption']),
        pathOr(false, ['SeatPositionPreference', 'SeatPosition']),
        pathOr(false, ['WagonCodePreference', 'WagonCode']),
        pathOr(false, ['BerthLevelPreference', 'BerthLevel']),
        pathOr(false, ['BerthCompartmentPreference', 'BerthCompartment']),
      ]
    ),
    pathOr({}, ['RailPreferences'])
  ),
  rawCompany: (v: ISearchEntity) => formatCompanyResponse(v.Company),
  sapCode: pathOr('N/A', ['SAPCode']),
  travelArrangers: pathOr([], ['Arrangers']),
};

export const formatProfile = applySpec<IProfile>(profileSpec);
export const unformatProfile = evolve(profileSpec);
